import React, { useEffect, useState, useRef } from "react";
import MainSidebar from "./MainSidebar";
import SideUl from "./SideUl";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import FreeTrail from "./FreeTrail";

const Reports = () => {
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "-4px" : "-50px",
  };
  const mainContentStyle = {
    // marginTop: isFreeTrialVisible ? "132px" : "92px",
  };
  const mainwrapper = {
    // marginTop: isSidebarVisible ? "0" : "0",
  };
  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     const sidebarElement = document.querySelector(".main-sidebar");
  //     const toggleButtonElement = document.querySelector(".sidebar-arrow a");

  //     if (
  //       sidebarElement &&
  //       !sidebarElement.contains(event.target) &&
  //       toggleButtonElement &&
  //       !toggleButtonElement.contains(event.target)
  //     ) {
  //       setSidebarVisible(false);
  //     }
  //   };

  //   document.addEventListener("click", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {" "}
          {isFreeTrial ? (
            <div className="app-container-trial">
              <FreeTrail />
            </div>
          ) : null}
          <div
            // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
            //   !isFreeTrialVisible ? "no-free-trial" : ""
            // }`}
            style={sidebarStyleone}
          >
            <nav
              className="navbar navbar-expand-lg main-navbar sticky"
              style={navbarStyle}
            >
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();

                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>Reports</h4>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />
            {/* Main Content */}
            <div className="main-content" style={mainContentStyle}>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="report_tabs">
                    <ul className="cus_navtabs">
                      <li>
                        <a href="#">Reports</a>
                      </li>
                      {/* <li>
                          <a href="#">Locations</a>
                        </li>
                        <li>
                          <a href="#">Performance</a>
                        </li> */}
                    </ul>
                    <div className="reports_content">
                      <div className="reports_column">
                        <h4>Sales Reports</h4>
                        <div className="report_form_field">
                          <div className="form_column">
                            <div className="form_field">
                              <input
                                type="text"
                                className="form-control ticketinput"
                                placeholder="Sales By date"
                                readOnly
                              />
                              <Link to="/saledate">
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </Link>
                            </div>
                          </div>
                          <div className="form_column">
                            <div className="form_field">
                              <input
                                type="text"
                                className="form-control ticketinput"
                                placeholder="Sales By Sales Person"
                                readOnly
                              />
                              <Link to="/saleperson">
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </Link>
                            </div>
                          </div>
                          {/* <div className="form_column">
                              <div className="form_field">
                                <input
                                  type="text"
                                  className="form-control ticketinput"
                                  placeholder="Sales By Customer"
                                  readOnly
                                />
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </div>
                            </div>
                            <div className="form_column">
                              <div className="form_field">
                                <input
                                  type="text"
                                  className="form-control ticketinput"
                                  placeholder="Sales By Payment Type"
                                  readOnly
                                />
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </div>
                            </div> */}
                        </div>
                      </div>
                      {/* <div className="reports_column">
                          <h4>Inventory Reports</h4>
                          <div className="report_form_field">
                            <div className="form_column">
                              <div className="form_field">
                                <input
                                  type="text"
                                  className="form-control ticketinput"
                                  placeholder="Sales By date"
                                  readOnly
                                />
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </div>
                            </div>
                            <div className="form_column">
                              <div className="form_field">
                                <input
                                  type="text"
                                  className="form-control ticketinput"
                                  placeholder="Sales By Sales Person"
                                  readOnly
                                />
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </div>
                            </div>
                            <div className="form_column">
                              <div className="form_field">
                                <input
                                  type="text"
                                  className="form-control ticketinput"
                                  placeholder="Sales By Customer"
                                  readOnly
                                />
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </div>
                            </div>
                            <div className="form_column">
                              <div className="form_field">
                                <input
                                  type="text"
                                  className="form-control ticketinput"
                                  placeholder="Sales By Payment Type"
                                  readOnly
                                />
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="reports_column">
                          <h4>Service Reports</h4>
                          <div className="report_form_field">
                            <div className="form_column">
                              <div className="form_field">
                                <input
                                  type="text"
                                  className="form-control ticketinput"
                                  placeholder="Services Created "
                                  readOnly
                                />
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
export default Reports;
