import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

import { checkSubDomainForLoginApi, checkSubdomainApi } from "../Utility/Api";

const SubDomainLogin = () => {
  const [subDomain, setSubDomain] = useState("");
  const [error, setError] = useState({});
  const [subdomainState, setSubdomainState] = useState(null);
  const [message, setMessage] = useState("");
  const [localState, setLocalState] = useState(false);
  const [sub, setSub] = useState("");
  let navigate = useNavigate();

  // useEffect(() => {
  //   // Define the target URL
  //   const targetUrl = "https://app.toolboxpos.com/Login";

  //   // Check if the current URL is not equal to the target URL
  //   if (window.location.href !== targetUrl) {
  //     // Navigate to the target URL
  //     window.location.replace(targetUrl);
  //   }
  // }, []);

  const CheckSubDomain = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      subDomain: subDomain,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(checkSubdomainApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.status === true) {
          setSubdomainState(true);
          // setMessage("Create SubDomain");
        } else {
          setSubdomainState(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const Validation = () => {
    let error = {};

    if (!subDomain) {
      error.subDomain = "Subdomain is required.";
    }
    if (Object.keys(error).length === 0) {
      //   console.log(Object.keys(error).length);

      CheckValidDomain();
    }
    return error;
  };
  const Register = () => {
    setError(Validation());
  };

  const CheckValidDomain = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      subDomain: subDomain,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("rwaaa", raw);

    fetch(checkSubDomainForLoginApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.status === true) {
          const protocol = window.location.protocol;
          let host = window.location.host;
          const subdomain = result?.data?.subDomain;
          const home = "/Account";
          let hostParts = host.split(".");
          if (hostParts[0] === "app") {
            hostParts.shift();
          }
          hostParts.unshift(subdomain);
          const newHost = hostParts.join(".");
          const newUrl = `${protocol}//${newHost}${home}`;
          // console.log("newUrl", newUrl);
          window.location.href = newUrl;

          // const protocol = window.location.protocol;
          // const host = window.location.host;
          // const subdomain = result?.data?.subDomain;
          // const home = "/Account";
          // const newUrl = `${protocol}//${subdomain}.${host}${home}`;
          // console.log("hostName", host);
          // window.location.href = newUrl;
          //navigate(newUrl, { state: result?.data?.subDomain });
          //navigate("/Login");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  // useEffect(() => {
  //   // const protocol = window.location.protocol;
  //   // const host = window.location.host;
  //   // const subdomain1 = host.split(".")[0];
  //   // console.log("host", host);
  //   // const isValidSubdomain = /^[^.]+/.test(host);

  //   let local = localStorage.getItem("token");
  //   if (local) {
  //     setLocalState(true);
  //   }
  // }, []);

  useEffect(() => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const subdomain = host.split(".")[0];
    const currentPath = window.location.pathname;
    const accountPage = "/Account";

    // console.log("subdomain", subdomain);
    // console.log("host", host);

    if (
      subdomain !== "app" &&
      subdomain !== "localhost:3000" &&
      currentPath !== accountPage
    ) {
      // console.log("account page...");
      const newUrl = `${protocol}//${host}${accountPage}`;
      window.location.href = newUrl;
    }

    // else {
    //   window.location.href = `${protocol}//${host}${accountPage}`;
    // }

    let local = localStorage.getItem("token");
    if (local) {
      setLocalState(true);
    }
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      Register();
    }
  };

  return (
    <>
      <div className="main-wrapper sublogincstm">
        <div className="login_page">
          <div className="regi_step_form">
            <form className="stepform">
              <div className="login_inner">
                <div className="login_left">
                  <div className="topheader">
                    <a href="#">
                      <img src="img/toolbox-logo-txt.webp" alt="" />
                    </a>
                  </div>
                  <div className="logo">
                    <a href="#">
                      <img src="img/landing-logo-icon.webp" alt="" />
                    </a>
                  </div>
                  <div className="other_link">
                    <ul>
                      <li>
                        <a href="#">Terms and conditions</a>
                      </li>
                      <li>
                        <a href="#">Privacy policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="login_right">
                  <div className="already_exist">
                    {/* <p>
                      Don't have an account? <Link to="/Signup">Sign up</Link>
                    </p> */}
                    {localState === true ? null : (
                      <p>
                        Don't have an account? <Link to="/Signup">Sign up</Link>
                      </p>
                    )}
                  </div>

                  <div className="form_area">
                    <div className="form_title">
                      <h4>Log into Toolbox</h4>
                    </div>
                    <div className="form_field">
                      <div className="form-group">
                        <div className="customform">
                          <label className="cus_label">Store Url</label>
                          <div className="input_field">
                            <input
                              type="text"
                              className="form-control"
                              name="store_url"
                              placeholder="Enter Store Url"
                              value={subDomain}
                              onChange={(e) => {
                                setSubDomain(e.target.value.trim());
                                // setSubdomainState(null);
                                setError("");
                              }}
                              // onBlur={CheckSubDomain}
                              onKeyUp={CheckSubDomain}
                              onKeyDown={handleKeyPress}
                            />
                            <span className="note">.toolboxpos.com</span>
                          </div>

                          {error.subDomain && (
                            <span className="customvalidationdesignlogin">
                              {error.subDomain}
                            </span>
                          )}
                          {subdomainState === true && (
                            <span className="customvalidationdesignlogin">
                              This Sub Domain doesn't exist.
                            </span>
                          )}
                        </div>
                      </div>
                      {subdomainState === true ? (
                        <div className="form-btn">
                          <button
                            type="button"
                            className="submit_btn disbledbtn"
                            name="submit"
                            onClick={() => Register()}
                            disabled
                          >
                            Next <img src="img/Login-next-arrow.webp" alt="" />
                          </button>
                        </div>
                      ) : (
                        <div className="form-btn">
                          <button
                            type="button"
                            className="submit_btn"
                            name="submit"
                            onClick={() => Register()}
                          >
                            Next <img src="img/Login-next-arrow.webp" alt="" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="already_exist"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default SubDomainLogin;
