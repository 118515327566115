import React, { useEffect, useState, useRef } from "react";
import MainSidebar from "./MainSidebar";
import SideUl from "./SideUl";
import Footer from "../Footer";
import FreeTrail from "./FreeTrail";
import generatePDF from "react-to-pdf";
import {
  SalesReportPersonApi,
  SalesReportPersonByDateApi,
} from "../Utility/Api";
import DatePicker from "react-datepicker";
import moment from "moment";

const SalesPerson = () => {
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [SalesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).startOf("day").subtract(0, "days")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).startOf("day").add(0, "days")
  );
  const [CompanyName, setCompanyName] = useState("");
  const targetRef = useRef();

  const handleCompanyNameChange = (name) => {
    setCompanyName(name);
  };

  const handleStartDate = (date) => {
    if (!date) return;

    setStartDate(moment(date).startOf("day"));
    setEndDate((prevEndDate) =>
      moment(date).startOf("day").isAfter(prevEndDate)
        ? moment(date).startOf("day").add(1, "days")
        : prevEndDate
    );
    // console.log("startdate", moment(date).startOf("day"));
  };

  const handleEndDate = (date) => {
    if (!date) return;

    setEndDate(moment(date).startOf("day").add(0, "days"));
    // console.log("enddate", moment(date).startOf("day").add(0, "days"));
    setTimeout(() => {
      SalesByPerson();
    }, 1000);
  };

  const SalesByPerson = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({});

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("SalesReportPersonApi", SalesReportPersonApi);
    fetch(SalesReportPersonApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("salespersondata", result);
        setLoading(false);
        setSalesData(result?.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    SalesByPerson();
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const SalesByPersonDate = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("dateraww", raw);
    // console.log("SalesReportPersonByDateApi", SalesReportPersonByDateApi);
    fetch(SalesReportPersonByDateApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setLoading(false);
        setSalesData(result?.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "-4px" : "-50px",
  };
  const mainContentStyle = {
    // marginTop: isFreeTrialVisible ? "132px" : "92px",
  };
  const mainwrapper = {
    // marginTop: isSidebarVisible ? "0" : "0",
  };
  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // const calculateTotals = (data) => {
  //   return data.reduce(
  //     (totals, item) => {
  //       totals.percentageOfSales += item?.percentageOfSales || 0;
  //       totals.totalCost += item?.totalCost || 0;
  //       totals.taxes += item?.taxes || 0;
  //       totals.cost += item?.cost || 0;
  //       totals.totalProfit += item?.totalProfit || 0;
  //       return totals;
  //     },
  //     {
  //       percentageOfSales: 0,
  //       totalCost: 0,
  //       taxes: 0,
  //       cost: 0,
  //       totalProfit: 0,
  //     }
  //   );
  // };

  // const totals = calculateTotals(SalesData);
  const formatNumber = (num) =>
    typeof num === "number" ? num.toFixed(2) : "0.00";
  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}
          {isFreeTrial ? (
            <div className="app-container-trial">
              <FreeTrail />
            </div>
          ) : null}
          <div
            // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
            //   !isFreeTrialVisible ? "no-free-trial" : ""
            // }`}
            style={sidebarStyleone}
          >
            <nav
              className="navbar navbar-expand-lg main-navbar sticky"
              style={navbarStyle}
            >
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();

                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>
                  Sales by Sales Person / <strong>Reports</strong>
                </h4>
              </div>
              <SideUl onCompanyNameChange={handleCompanyNameChange} />
            </nav>
            <MainSidebar />
            {/* Main Content */}
            <div className="main-content tableback" style={mainContentStyle}>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="common_sales_report">
                    <div className="sales_filter">
                      <div className="sales_col_left">
                        <div className="sales_person cus_sales_type">
                          <label>Sales Person:</label>
                          <select
                            className="form-control"
                            onClick={SalesByPerson}
                          >
                            <option value="All">All</option>
                            {/* <option value="None">None</option> */}
                          </select>
                        </div>
                        <div className="sales_date">
                          <div className="date_icon">
                            <img src="assets/img/sales-date-icon.svg" alt="" />
                          </div>
                          <div className="date_rangepicker">
                            <DatePicker
                              id="startDate"
                              className="saledashstartdatepicker"
                              dateFormat="MM/dd/yyyy"
                              selected={startDate.toDate()}
                              onChange={handleStartDate}
                              // showMonthYearPicker
                            />
                            <span>-</span>
                            <DatePicker
                              id="endDate"
                              className="saledashenddatepicker"
                              dateFormat="MM/dd/yyyy"
                              minDate={startDate.toDate()}
                              selected={endDate.toDate()}
                              onChange={handleEndDate}
                              // showMonthYearPicker
                            />
                          </div>
                        </div>
                      </div>
                      <div className="sales_col_right">
                        <div className="sales_save_btn">
                          <button
                            className="btn orderbtn "
                            type="button"
                            onClick={SalesByPersonDate}
                            // data-toggle="dropdown"
                            // aria-expanded="true"
                          >
                            Submit Date
                          </button>
                          <div className="dropdown">
                            <button
                              className="btn orderbtn "
                              type="button"
                              data-toggle="dropdown"
                              aria-expanded="true"
                            >
                              Save
                            </button>
                            <ul
                              className="dropdown-menu"
                              x-placement="top-start"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() =>
                                    generatePDF(targetRef, {
                                      filename: "page.pdf",
                                    })
                                  }
                                >
                                  Save as a PDF
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sales_content">
                      <div className="sales_title">
                        <h4>Sales by Sales Person</h4>
                        <span className="comp_name">{CompanyName}</span>
                        <span className="saledate">
                          {startDate.format("MM/DD/YYYY")} -{" "}
                          {endDate.format("MM/DD/YYYY")}
                        </span>
                      </div>
                      <div className="sales_inner_content">
                        <div className="table-responsive" ref={targetRef}>
                          <table className="table">
                            <tbody>
                              <tr>
                                <td>
                                  <b>Sales Person</b>
                                  <br />
                                  Name
                                </td>
                                <td>
                                  <b>Percentage of sales</b>
                                  <br />
                                  (%) of total sales
                                </td>
                                <td>
                                  <b>Total Sales</b>
                                  <br />
                                  Sales in $
                                </td>
                                <td>
                                  <b>Taxes</b>
                                  <br />
                                  Sales in $
                                </td>
                                <td>
                                  <b>Cost</b>
                                  <br />
                                  Sales in $
                                </td>
                                <td>
                                  <b>Profit</b>
                                  <br />
                                  Sales in $
                                </td>
                              </tr>
                              {SalesData?.length ? (
                                <>
                                  {SalesData?.map((item, index) => (
                                    <>
                                      <tr key={item?.id}>
                                        <td>{item?.username}</td>
                                        <td>
                                          {item?.percentageOfSales != null
                                            ? item.percentageOfSales.toFixed(2)
                                            : 0}
                                          %
                                        </td>
                                        <td>
                                          ${formatNumber(item?.totalCost)}
                                        </td>
                                        <td>${formatNumber(item?.taxes)}</td>
                                        <td>${formatNumber(item?.cost)}</td>
                                        <td>
                                          ${formatNumber(item?.totalProfit)}
                                        </td>
                                        {/* <td></td>
                                          <td></td> */}
                                      </tr>
                                    </>
                                  ))}
                                  {/* <tr>
                                    <td>Total</td>
                                    <td>
                                      {totals.percentageOfSales.toFixed(2)}%
                                    </td>
                                    <td>${totals.totalCost.toFixed(2)}</td>
                                    <td>${totals.taxes.toFixed(2)}</td>
                                    <td>${totals.cost.toFixed(2)}</td>
                                    <td>${totals.totalProfit.toFixed(2)}</td>
                                  </tr> */}
                                </>
                              ) : (
                                <tr id="noDataMessage">
                                  <td colSpan="10" className="nodataav">
                                    No data available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
export default SalesPerson;
