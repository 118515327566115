import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  TimeZoneAddApi,
  TimeZoneGetApi,
  TimeZoneUpdateApi,
} from "../Utility/Api";
import Swal from "sweetalert2";
import moment from "moment-timezone";

const TimeSettings = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [currency, setCurrency] = useState("$");
  const [timeZone, setTimeZone] = useState(moment.tz.guess());
  const [existingTimeZone, setExistingTimeZone] = useState(null);
  // const [timeZone, setTimeZone] = useState();

  const handleChange = (event) => {
    setTimeZone(event.target.value);
    if (error.timeZone) {
      setError({ ...error, timeZone: "" });
    }
  };

  const currentTime = moment().tz(timeZone).format("YYYY-MM-DD HH:mm:ss");

  // console.log("currentTime", currentTime);
  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  // const Validations = () => {
  //   let error = {};
  //   if (!timeZone) {
  //     error.timeZone = "Time Zone is required.";
  //   }

  //   if (Object.keys(error).length === 0) {
  //     //console.log(Object.keys(error).length);
  //     if (timeZone) {
  //       UpdateTimeZone();
  //     }
  //     TimeZone();
  //   }
  //   return error;
  // };

  // const SubmitTimeZone = () => {
  //   setError(Validations());
  // };

  const Validations = () => {
    let error = {};
    if (!timeZone) {
      error.timeZone = "Time Zone is required.";
    }
    return error;
  };

  const SubmitTimeZone = () => {
    const validationErrors = Validations();
    setError(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      if (existingTimeZone) {
        UpdateTimeZone();
      } else {
        TimeZone();
      }
    }
  };

  const TimeZone = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      timeZone: timeZone,
      currency: currency,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("timeZone", raw);

    fetch(TimeZoneAddApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          // console.log("result timezone", result);
          setLoading(false);
          Swal.fire({
            title: "Success!",
            text: "Time Zone has been inserted successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
          // //setShowModal(false);
          // // setEmail("");
          // navigate("/invoicelist");
          GetTimeZone();
        } else {
          setLoading(false);
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const UpdateTimeZone = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      timeZone: timeZone,
      currency: currency,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("timeZone", raw);

    fetch(TimeZoneUpdateApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          // console.log("Update result timezone", result);
          setLoading(false);
          Swal.fire({
            title: "Success!",
            text: "Time Zone has been Updated successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
          // //setShowModal(false);
          // // setEmail("");
          // navigate("/invoicelist");
          GetTimeZone();
        } else {
          setLoading(false);
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const GetTimeZone = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(TimeZoneGetApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log("ticket list", result);
        if (result?.data?.timeZone) {
          setTimeZone(result?.data?.timeZone);
          setExistingTimeZone(result?.data?.timeZone);
        }
        if (result?.data?.currency) {
          setCurrency(result?.data?.currency);
        } else {
          setLoading(false);
        }
        setLoading(false);
      })

      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    GetTimeZone();
  }, []);

  // Function to get offset for a given time zone
  const getOffset = (zone) => {
    const offset = moment.tz(zone).utcOffset();
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;
    const sign = offset >= 0 ? "+" : "-";
    const offsetString = `${sign}${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
    return `UTC${offsetString} / GMT${offsetString}`;
  };
  return (
    <React.Fragment>
      {loading ? (
        <div className="landing_loader">
          <div className="innerloader">
            <img src="img/spin-1s-156px.gif" alt="" />
          </div>
        </div>
      ) : null}
      <div className="setting_content receipt_config_setup">
        <h4>Time SetUp</h4>
        <div className="inner_block plan_billing_setting">
          <form className="setting_form" action="" method="post">
            <div className="block_column wd100">
              <h5>Time Zone Setup</h5>
              <p>
                Here you can manage and configure your store's time zone and
                currency settings, how they will look and how they will be used.
              </p>
              <div className="inner_field">
                <div className="receipt_other_info">
                  <div
                    className="reciept_blockbox header_receipt_block"
                    style={{ border: "0px", margin: "0px", padding: "0px" }}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="cus_label">Select Currency:</label>
                          <div className="size_field">
                            <select
                              className="form-control"
                              id="currency"
                              value={currency}
                              onChange={handleCurrencyChange}
                            >
                              <option value="$">$, Dollar</option>
                            </select>

                            <span className="receipt_note">
                              Choose the currency you want to select
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="cus_label">Select Time Zone:</label>
                          <div className="size_field">
                            <select
                              className="form-control"
                              value={timeZone}
                              onChange={handleChange}
                            >
                              {moment.tz.names().map((tz) => (
                                <option key={tz} value={tz}>
                                  {`${getOffset(tz)} - ${tz} `}
                                </option>
                              ))}
                              {/* {moment.tz.names().map((tz) => (
                                <option key={tz} value={tz}>
                                  {tz}
                                </option>
                              ))} */}
                            </select>

                            <span className="receipt_note">
                              Choose the time zone you want to select
                            </span>
                            {error.timeZone && (
                              <span className="customvalidationdesignlogin">
                                {error.timeZone}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </form>
          <div className="clearfix"></div>
          <div className="editsavechangesbtn">
            <button
              type="button"
              className="btnprofileinfo"
              onClick={SubmitTimeZone}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default TimeSettings;
