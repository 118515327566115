import React, { useState } from "react";
import { forgotPassApi, otpVerifyApi } from "../Utility/Api";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

const ForgotPass = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState({});
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [OTPState, setOTPstate] = useState(false);
  const [OTP, setOTP] = useState("");

  let navigate = useNavigate();

  const isDisposableEmail = (email) => {
    const domain = email.split("@")[1];
    return disposableEmailDomains.includes(domain);
  };

  const disposableEmailDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "aol.com",
    "icloud.com",
    "mail.com",
    "yandex.com",
    "protonmail.com",
    "zoho.com",
    "gmx.com",
    "ymail.com",
    "comcast.net",
    "me.com",
    "msn.com",
    "live.com",
    "sbcglobal.net",
    "verizon.net",
    "att.net",
    "cox.net",
    "smartitventures.com",
    // Add more domains as needed
  ];

  const Validation = () => {
    let error = {};
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (!email || !reg.test(email)) {
      error.email = "Please provide a valid email address.";
    } else if (email && !isDisposableEmail(email)) {
      error.email =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }

    if (Object.keys(error).length === 0) {
      //console.log(Object.keys(error).length);
      ForgotApi();
    }
    return error;
  };

  const OnSubmit = () => {
    setError(Validation());
  };
  const Validation1 = () => {
    let error = {};
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (!OTP) {
      error.OTP = "Please provide a valid OTP.";
    }

    if (Object.keys(error).length === 0) {
      //console.log(Object.keys(error).length);
      OTPVerifyApi();
    }
    return error;
  };

  const OnSubmit1 = () => {
    setError(Validation1());
  };

  const OTPVerifyApi = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
      otp: OTP,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(otpVerifyApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          //setMessage(result?.message);
          Swal.fire(result?.message);
          setLoading(false);
          setOTP("");
          navigate("/updatepassword", { state: { email: email } });
        } else {
          //setMessage(result?.message);
          setLoading(false);
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const ForgotApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(forgotPassApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          //setMessage(result?.message);
          // Swal.fire({
          //   title: "Verify Link sent to email successfully!!",
          //   customClass: {
          //     container: "custom-swal-container",
          //     confirmButton: "custom-swal-confirm-button",
          //   },
          // });
          // Swal.fire(result?.message);
          // Receipt sent to email successfully!
          localStorage.setItem("email", email);
          navigate("/succeslink");
          //setOTPstate(true);

          setLoading(false);
        } else {
          // setMessage(result?.message);
          Swal.fire(result?.message);
          setLoading(false);
          // setOTPstate(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const Register = () => {
    window.location.href = "https://app.toolboxpos.com/Signup";
  };

  const BackLogin = () => {
    window.location.href = "https://app.toolboxpos.com/Login";
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      OnSubmit1();
    }
  };
  const handleKeyPress1 = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      OnSubmit();
    }
  };

  return (
    <>
      {loading ? (
        <div className="landing_loader">
          <div className="innerloader">
            <img src="img/spin-1s-156px.gif" alt="" />
          </div>
        </div>
      ) : null}
      <div className="main-wrapper">
        <div className="login_page">
          <div className="regi_step_form">
            <form className="stepform">
              <div className="login_inner">
                <div className="login_left">
                  <div className="topheader">
                    <a href="#">
                      <img src="img/toolbox-logo-txt.webp" alt="" />
                    </a>
                  </div>
                  <div className="logo">
                    <a href="#">
                      <img src="img/landing-logo-icon.webp" alt="" />
                    </a>
                  </div>
                  <div className="other_link">
                    <ul>
                      <li>
                        <a href="#">Terms and conditions</a>
                      </li>
                      <li>
                        <a href="#">Privacy policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="login_right">
                  <div
                    className="not_store"
                    style={{ cursor: "pointer" }}
                    onClick={BackLogin}
                  >
                    <p>Not your store?</p>
                  </div>

                  {OTPState === true ? (
                    <>
                      <div className="form_area">
                        <div className="form_title">
                          <h4>Verify OTP</h4>
                          <p>
                            Please enter your OTP associated with your account
                            below, and we’ll email you a link with instructions
                            to reset your password.
                          </p>
                        </div>
                        <div className="form_field forgot_field">
                          <div className="form-group">
                            <label className="cus_label">OTP</label>
                            <div className="input_field">
                              <input
                                className="form-control no-arrow-input"
                                type="number"
                                value={OTP}
                                name="OTP"
                                onChange={(e) => {
                                  setOTP(e.target.value.trim());
                                }}
                                placeholder="Enter Otp *"
                                onKeyDown={handleKeyPress}
                              />
                            </div>
                            {error.OTP && (
                              <span className="customvalidationdesignlogin">
                                {error.OTP}
                              </span>
                            )}
                            {/* {message && (
                              <span className="customvalidationdesignlogin">
                                {message}
                              </span>
                            )} */}
                          </div>
                          <div className="form-btn">
                            <button
                              type="button"
                              className="submit_btn"
                              // name="submit"
                              onClick={OnSubmit1}
                            >
                              Submit{" "}
                              <img src="img/login-next-arrow.webp" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form_area">
                        <div className="form_title">
                          <h4>Forgot Password?</h4>
                          <p>
                            Please enter your email associated with your account
                            below, and we’ll email you a link with insurrections
                            to reset your password.
                          </p>
                        </div>
                        <div className="form_field forgot_field">
                          <div className="form-group">
                            <label className="cus_label">Email</label>
                            <div className="input_field">
                              <input
                                className="form-control"
                                type="email"
                                value={email}
                                name="email"
                                onChange={(e) => {
                                  setEmail(e.target.value.trim());
                                }}
                                placeholder="Email Address *"
                                onKeyDown={handleKeyPress1}
                              />
                            </div>
                            {error.email && (
                              <span className="customvalidationdesignlogin">
                                {error.email}
                              </span>
                            )}
                            {message && (
                              <span className="customvalidationdesignlogin">
                                {message}
                              </span>
                            )}
                          </div>
                          <div className="form-btn">
                            <button
                              type="button"
                              className="submit_btn"
                              // name="submit"
                              onClick={OnSubmit}
                            >
                              Send Link{" "}
                              <img src="img/login-next-arrow.webp" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="already_exist">
                    <p>
                      Don't have an account? <a onClick={Register}>Sign up</a>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default ForgotPass;
