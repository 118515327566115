import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  AddCashLayoutApi,
  AddPaymentOptionApi,
  baseurl,
  getProfileApi,
  GettAllPaymentApi,
} from "../Utility/Api";
import Swal from "sweetalert2";

const PaymentOptions = () => {
  const [paymentName, setPaymentName] = useState("");
  const [allPaymentOptions, setAllPaymentOptions] = useState([]);
  const [error, setError] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [id, setID] = useState("");

  useEffect(() => {
    GetPaymentOptions();
  }, []);

  const GetPaymentOptions = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GettAllPaymentApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllPaymentOptions(result?.data.reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Validation = () => {
    let error = {};
    if (!paymentName) {
      error.paymentName = "Payment Option Name is required.";
    }

    if (Object.keys(error).length === 0) {
      AddPaymentOptions();
    }
    return error;
  };

  const Submit = () => {
    setError(Validation());
  };

  const Validation1 = () => {
    let error = {};
    if (!paymentName) {
      error.paymentName = "Payment Option Name is required.";
    }

    if (Object.keys(error).length === 0) {
      EditPaymentOptions();
    }
    return error;
  };

  const Submit1 = () => {
    setError(Validation1());
  };

  const AddPaymentOptions = () => {
    let local = localStorage.getItem("token");
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: paymentName,
      defaultPaymentOption: 0,
      activeInactive: 0,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddPaymentOptionApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setShowModal(false);
          GetPaymentOptions();
          Swal.fire(result?.message);
        } else {
          setShowModal(false);
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const EditPaymentOptions = () => {
    let local = localStorage.getItem("token");
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: paymentName,
      defaultPaymentOption: 0,
      activeInactive: 0,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updatePaymentOption/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetPaymentOptions();
          setShowModal1(false);
          Swal.fire(result?.message);
        } else {
          setShowModal1(false);
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateOption = (paymentName, id) => {
    let local = localStorage.getItem("token");
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: paymentName?.name,
      defaultPaymentOption: 1,
      activeInactive: paymentName?.activeInactive,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updatePaymentOption/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetPaymentOptions();
          setShowModal1(false);
          Swal.fire(result?.message);
        } else {
          setShowModal1(false);
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateOptiontoggle = (ite, i) => {
    let local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: ite?.name,
      defaultPaymentOption: ite?.defaultPaymentOption,
      activeInactive: i === true ? 1 : 0,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updatePaymentOption/${ite?.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetPaymentOptions();
          setShowModal1(false);
          Swal.fire(result?.message);
        } else {
          setShowModal1(false);
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const HandleEdit = (item) => {
    setShowModal1(true);
    setPaymentName(item?.name);
    setID(item?.id);
  };
  const HandleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Payment Option",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          deletePayment(id);
        });
      }
    });
  };

  const deletePayment = (id) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}deletePaymentOption/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        GetPaymentOptions();
      })
      .catch((error) => console.error(error));
  };

  const [toggleState, setToggleState] = useState(false);

  const handleToggleChange = (isChecked, item) => {
    setToggleState(isChecked);
    UpdateOptiontoggle(isChecked, item);
  };

  return (
    <>
      <div className="setting_content">
        <h4>Payment Option</h4>
        <div className="inner_block plan_billing_setting">
          <form className="setting_form" action="" method="post">
            <div className="block_column wd100">
              <h5>Accepted Payment Option</h5>
              <p>
                Manage the payment options you will receive for you cash
                resister.
              </p>
              <div className="inner_field">
                <div className="tax_label_block">
                  <div className="tax_label">
                    <h6>Default Payment option</h6>
                    <span>
                      This is the defult payment method shown in in cash
                      resgister.
                    </span>
                  </div>
                  <div className="tax_field">
                    <select
                      className="form-control"
                      name="payment_option"
                      onChange={(e) => {
                        UpdateOption(
                          e.target.value,
                          e.target.options[e.target.selectedIndex].id
                        );
                      }}
                    >
                      {" "}
                      <option value="">Select Payment Option</option>
                      {allPaymentOptions
                        ?.filter((itm) => itm?.activeInactive === 1)
                        .map((item, index) => (
                          <option value={item} key={item.id} id={item.id}>
                            {item?.name}
                          </option>
                        ))}
                    </select>

                    <img src="assets/img/select-arrow.webp" alt="" />
                  </div>
                </div>
                <div className="tax_label_block">
                  <div className="tax_label">
                    <h6>Store Payment Options</h6>
                    <span>All the currencies available in your store.</span>
                  </div>
                </div>
                <div className="status_table">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Store Payment Options</th>
                          <th>On/Off</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allPaymentOptions?.map((item, index) => (
                          <>
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item?.name}</td>
                              <td>
                                <div className="taxfield switch_field">
                                  <label className="custom-switch">
                                    <input
                                      type="checkbox"
                                      name="custom-switch-checkbox"
                                      className="custom-switch-input"
                                      checked={item.activeInactive === 1}
                                      onChange={(e) =>
                                        handleToggleChange(
                                          item,
                                          e.target.checked
                                        )
                                      }
                                    />
                                    <span className="custom-switch-indicator"></span>
                                  </label>
                                </div>
                              </td>
                              <td>
                                <div className="status_edit_btn">
                                  <a
                                    className="editbtn"
                                    data-toggle="modal"
                                    data-target="#editpaymentoptionModal"
                                    href="#"
                                    onClick={() => HandleEdit(item)}
                                  >
                                    Edit
                                  </a>
                                  <a
                                    className="deletebtn"
                                    href="#"
                                    onClick={() => HandleDelete(item?.id)}
                                  >
                                    Delete
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}

                        <tr className="add_row">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td
                            className="add_btn"
                            onClick={() => {
                              setShowModal(true);
                              setPaymentName("");
                            }}
                          >
                            <a
                              data-toggle="modal"
                              data-target="#addpaymentoptionModal"
                              href="javascript:;"
                            >
                              + <span>Add New</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="clearfix"></div>
        </div>
      </div>
      {showModal === true ? (
        <>
          <div
            className="modal common_modal status_option_modal"
            id="addpaymentoptionModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="addpaymentoptionModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addpaymentoptionModalLabel">
                    Add Payment Option
                  </h5>
                  <p>Add Payment options to your register</p>
                </div>
                <div className="modal-body">
                  <div className="prod_status_sec">
                    <form method="" action="">
                      <div className="field_row">
                        <div className="field_col col_full">
                          <div className="status_name">
                            <input
                              type="text"
                              className="form-control"
                              style={{ textTransform: "capitalize" }}
                              placeholder="Enter Payment Option"
                              value={paymentName}
                              onChange={(e) => {
                                setPaymentName(e.target.value);
                                if (error.paymentName) {
                                  setError({ ...error, paymentName: "" });
                                }
                              }}
                            />
                          </div>
                          {error.paymentName && (
                            <span className="customvalidationdesignlogin">
                              {error.paymentName}
                            </span>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    data-dismiss="modal"
                    onClick={() => {
                      setShowModal(false);
                      setPaymentName("");
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={Submit}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showModal1 === true ? (
        <>
          <div
            className="modal common_modal status_option_modal"
            id="editpaymentoptionModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="editpaymentoptionModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="editpaymentoptionModalLabel">
                    Edit Payment Option
                  </h5>
                  <p>Edit Payment options to your register</p>
                </div>
                <div className="modal-body">
                  <div className="prod_status_sec">
                    <form method="" action="">
                      <div className="field_row">
                        <div className="field_col col_full">
                          <div className="status_name">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Payment Option"
                              value={paymentName}
                              style={{ textTransform: "capitalize" }}
                              onChange={(e) => {
                                setPaymentName(e.target.value);
                                if (error.paymentName) {
                                  setError({ ...error, paymentName: "" });
                                }
                              }}
                            />
                          </div>
                          {error.paymentName && (
                            <span className="customvalidationdesignlogin">
                              {error.paymentName}
                            </span>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    data-dismiss="modal"
                    onClick={() => {
                      setShowModal1(false);

                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={Submit1}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default PaymentOptions;
