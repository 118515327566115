import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";

import {
  AddBrandApi,
  AddCarrierApi,
  AddColorApi,
  AddConditionApi,
  AddCustomerDeviceApi,
  AddItemApi,
  AddManufactureApi,
  AddProductApi,
  AddStorageApi,
  AddSupplierApi,
  AddTagsApi,
  GetAllBrandApi,
  GetAllColorApi,
  GetAllConditionApi,
  GetAllItemApi,
  GetAllProductApi,
  GetAllStorageApi,
  GetAllSupplierApi,
  GetCustomerDeviceApi,
  GetManufactureApi,
  GetTagsApi,
  baseurl,
  customerAddApi,
  customerGetApi,
  uploadImageApi,
} from "../Utility/Api";
import { Link, useNavigate } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import Swal from "sweetalert2";

const TicketDeviceModal = (props) => {
  const [images, setImages] = useState("");
  const [error, setError] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm1, setSearchTerm1] = useState("");
  const [searchTermCategory, setSearchTermCategory] = useState("");
  const [searchTermSuppiler, setsearchTermSuppiler] = useState("");
  const [searchTermTags, setsearchTermTags] = useState("");
  const [imageMsg, setImageMsg] = useState("");
  const [showModal4, setShowModal4] = useState(false);
  const [AllBrand, setAllBrand] = useState([]);
  const [AllBrand1, setAllBrand1] = useState([]);
  const [AllSupplier, setAllSupplier] = useState([]);
  const [SKU, setSKU] = useState("");
  const [AllItems, setAllItems] = useState([]);
  const [AllTags, setAllTags] = useState([]);
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [unitCost, setUnitCost] = useState("");
  const [minimumPrice, setMinimunPrice] = useState("");
  const [averageCost, setAverageCost] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [selectedBrandId1, setSelectedBrandId1] = useState(null);
  const [selectedCateId, setSelectedCateId] = useState(null);
  const [selectedSupplierId, setSelectedSuppierId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [showList, setShowList] = useState(false);
  const [showList1, setShowList1] = useState(false);
  const [showListCategory, setShowListCategory] = useState(false);
  const [showListSuppiler, setShowListSuppiler] = useState(false);
  const [showListTags, setShowListTags] = useState(false);
  const [typedText, setTypedText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSuppiler, setSelectedSuppiler] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [autogenrate, setAutoGenrate] = useState(0);
  const [skuFill, setSKUFill] = useState("");
  const [autogenText, setAutoGenTex] = useState("Auto Generated");
  const [showListAuto, setShowListAuto] = useState(false);
  const [errorBrand, setErrorBrand] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [newOption, setNewOption] = useState("");
  const [options, setOptions] = useState(["Option 1", "Option 2", "Option 3"]);
  const [imeiNumber, setIMEINumber] = useState("");
  const [showListStorage, setShowListStorage] = useState(false);
  const [showListColor, setShowListColor] = useState(false);
  const [showListCondition, setShowListCondition] = useState(false);
  const [showListCarrier, setShowListCarrier] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedStorageId, setSelectedStorageId] = useState(null);
  const [selectedColorId, setSelectedColorId] = useState(null);
  const [selectedConditionId, setSelectedConditionId] = useState(null);
  const [selectedCarrierId, setSelectedCarrierId] = useState(null);
  const [allColor, setAllColor] = useState([]);
  const [allStorage, setAllStorage] = useState([]);
  const [allCondition, setAllCondition] = useState([]);
  const [allCarrier, setAllCarrier] = useState([]);
  const [searchTermStorage, setSearchTermStorage] = useState("");
  const [searchTermColor, setsearchTermColor] = useState("");
  const [selectedStorage, setSelectedStorage] = useState(null);
  const [searchTermCondition, setSearchTermCondition] = useState("");
  const [searchTermCarrier, setSearchTermCarrier] = useState("");
  const [allcustomerDevice, setAllCustomerDevice] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [images1, setImages1] = useState("");

  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);
  const inputRef7 = useRef(null);
  const inputRef8 = useRef(null);
  const inputRef9 = useRef(null);
  const inputRef10 = useRef(null);
  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);
  const dropdownRef3 = useRef(null);
  const dropdownRef4 = useRef(null);
  const dropdownRef5 = useRef(null);
  const dropdownRef6 = useRef(null);
  const dropdownRef7 = useRef(null);
  const dropdownRef8 = useRef(null);
  const dropdownRef9 = useRef(null);
  const dropdownRef10 = useRef(null);

  const handleButtonClickStorage = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListStorage(!showListStorage);
  };

  const filteredStorage = allStorage.filter((brand) => {
    const name = brand.size;
    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermStorage.toLowerCase());
    }

    return false; // Or handle null or non-string values as needed
  });

  const handleSearchChangeStorage = (e) => {
    setSearchTermStorage(e.target.value);
    setTypedText(e.target.value);
    setSelectedStorage(null); // Reset selected brand when typing in the input field
  };

  const handleStorageSelect = (storage) => {
    setSelectedStorage(storage.size);
    setSelectedStorageId(storage.id);
    setShowListStorage(false);
  };

  const handleAddNewClickStorage = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Category name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    //e.preventDefault();
    setSelectedStorage(typedText);
    setShowListStorage(false);
    setSearchTermStorage("");
    setTypedText("");
    AddStorage();
  };

  const filteredCondition = allCondition.filter((item) => {
    const name = item.condition;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermCondition.toLowerCase());
    }

    return false;
  });
  const filteredCarrier = allCarrier.filter((item) => {
    const name = item.name;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermCarrier.toLowerCase());
    }

    return false;
  });
  const filteredColor = allColor.filter((item) => {
    const name = item.name;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermColor.toLowerCase());
    }

    return false;
  });

  const handleSearchChangeColor = (e) => {
    setsearchTermColor(e.target.value);
    setTypedText(e.target.value);
    setSelectedColor(null);
  };

  const handleSearchChangeCondition = (e) => {
    setSearchTermCondition(e.target.value);
    setTypedText(e.target.value);
    setSelectedCondition(null);
  };
  const handleSearchChangeCarrier = (e) => {
    setSearchTermCarrier(e.target.value);
    setTypedText(e.target.value);
    setSelectedCarrier(null);
  };
  const handleColorSelect = (item) => {
    setSelectedColor(item.name);
    setSelectedColorId(item.id);
    setShowListColor(false);
  };

  const handleConditionSelect = (item) => {
    setSelectedCondition(item.condition);
    setSelectedConditionId(item.id);
    setShowListCondition(false);
  };

  const handleCarrierSelect = (brand) => {
    setSelectedCarrier(brand.name);
    setSelectedCarrierId(brand.id);
    setShowListCarrier(false);
  };

  const handleAddNewClickColor = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Color name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    //  e.preventDefault();
    setSelectedColor(typedText);
    setShowListColor(false);
    setsearchTermColor("");
    setTypedText("");
    AddColorDevice();
  };

  const handleAddNewClickCondition = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Condition!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    // setSelectedSuppiler(typedText);
    setSelectedCondition(typedText);
    setShowListCondition(false);
    setSearchTermCondition("");
    setTypedText("");
    AddCondition();
  };

  const handleAddNewClickCarrier = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Carrier name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    // setSelectedSuppiler(typedText);
    setSelectedCarrier(typedText);
    setShowListCarrier(false);
    setSearchTermCarrier("");
    setTypedText("");
    AddCarrier();
  };

  const AddColorDevice = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: typedText,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddColorApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllColor();
          setSelectedColorId(result?.data?.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const AddCondition = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3A-_3JwaXdjnqK5jnz_MupfyYwBOQ-xwpN.F15%2BK8LY79Ye8glfaA8Fk2LX1rgoPDz%2BrF7ZAbnGd58"
    );

    var raw = JSON.stringify({
      condition: typedText,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddConditionApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllCondition();
          setSelectedConditionId(result?.data?.id);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GettAllCarrier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3A-_3JwaXdjnqK5jnz_MupfyYwBOQ-xwpN.F15%2BK8LY79Ye8glfaA8Fk2LX1rgoPDz%2BrF7ZAbnGd58"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllCarrier`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllCarrier(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddCarrier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 2,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddCarrierApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GettAllCarrier();
          setSelectedCarrierId(result?.data?.id);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const GetAllStorage = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllStorageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllStorage(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleButtonClickColor = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListColor(!showListColor);
  };

  const handleButtonClickCondition = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListCondition(!showListColor);
  };

  const handleButtonClickCarrier = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListCarrier(!showListColor);
  };

  const GetAllColor = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllColorApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllColor(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllCondition = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllConditionApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllCondition(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddStorage = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      size: typedText,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddStorageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllStorage();
          setSelectedStorageId(result?.data?.id);
          setSearchTermStorage("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setShowListCategory(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef1]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setShowListSuppiler(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef2]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef3.current &&
        !dropdownRef3.current.contains(event.target)
      ) {
        setShowListTags(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef3]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef5.current &&
        !dropdownRef5.current.contains(event.target)
      ) {
        setShowList1(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef5]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef6.current &&
        !dropdownRef6.current.contains(event.target)
      ) {
        setShowListStorage(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef6]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef7.current &&
        !dropdownRef7.current.contains(event.target)
      ) {
        setShowListColor(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef7]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef8.current &&
        !dropdownRef8.current.contains(event.target)
      ) {
        setShowListCondition(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef8]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef9.current &&
        !dropdownRef9.current.contains(event.target)
      ) {
        setShowListCarrier(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef9]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef10.current &&
        !dropdownRef10.current.contains(event.target)
      ) {
        setShowListSuppiler(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef10]);

  const filteredBrands = AllBrand.filter((brand) => {
    const name = brand.name;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTerm.toLowerCase());
    }

    return false; // Or handle null or non-string values as needed
  });

  const filteredBrands1 = AllBrand1.filter((brand) => {
    const name = brand.name;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTerm1.toLowerCase());
    }

    return false; // Or handle null or non-string values as needed
  });

  const handleButtonClickAuto = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListAuto(!showListAuto);
  };
  const handleButtonClick = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowList(!showList);
  };

  const handleButtonClick1 = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowList1(!showList1);
  };
  const handleButtonClickCategory = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListCategory(!showListCategory);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setTypedText(e.target.value);
    setSelectedBrand(null);
  };

  const handleSearchChange1 = (e) => {
    setSearchTerm1(e.target.value);
    setTypedText(e.target.value);
    setSelectedBrand1(null);
  };

  const handleBrandSelect = (brand) => {
    setSelectedBrand(brand.name);
    setSelectedBrandId(brand.id);
    setShowList(false);
  };

  const handleBrandSelect1 = (brand) => {
    setSelectedBrand1(brand.name);
    setSelectedBrandId1(brand.id);
    setShowList1(false);
  };

  const handleAddNewClick = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid brand name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    setSelectedBrand(typedText);
    setShowList(false);
    setSearchTerm("");
    setTypedText("");
    AddBrand();
  };

  const filteredCategory = AllItems.filter((brand) => {
    const name = brand.name;

    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermCategory.toLowerCase());
    }

    return false;
  });

  const handleSearchChangeCategory = (e) => {
    setSearchTermCategory(e.target.value);
    setTypedText(e.target.value);
    setSelectedCategory(null);
  };

  const handleCategorySelect = (brand) => {
    setSelectedCategory(brand.name);
    setSelectedCateId(brand.id);
    setShowListCategory(false);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Backspace") {
      setShowList(true);
    }
  };
  const handleAddNewClickCategory = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Category name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    setSelectedCategory(typedText);
    setShowListCategory(false);
    setSearchTermCategory("");
    setTypedText("");
    AddItem();
  };

  const filteredSuppiler = AllSupplier.filter((brand) => {
    const name = brand.name;

    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermSuppiler.toLowerCase());
    }

    return false;
  });

  const filteredTags = AllTags.filter((brand) => {
    const name = brand.name;

    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermTags.toLowerCase());
    }

    return false;
  });

  const handleSearchChangeSupplier = (e) => {
    setsearchTermSuppiler(e.target.value);
    setTypedText(e.target.value);
    setSelectedSuppiler(null); // Reset selected brand when typing in the input field
  };
  const handleSearchChangeTags = (e) => {
    setsearchTermTags(e.target.value);

    setTypedText(e.target.value);
  };
  const handleInputClick = () => {
    setShowListTags(!showListTags); // Show the list of tags when the input is clicked
  };
  const handleSupplierSelect = (brand) => {
    setSelectedSuppiler(brand.name);
    setSelectedSuppierId(brand.id);
    setShowListSuppiler(false);
  };

  const handleTagsSelect = (selectedTag) => {
    // Check if the selected tag is already in the selectedTags array
    const isAlreadySelected = selectedTags.some(
      (tag) => tag.id === selectedTag.id
    );

    if (isAlreadySelected) {
      // If the tag is already selected, remove it
      const updatedTags = selectedTags.filter(
        (tag) => tag.id !== selectedTag.id
      );
      setSelectedTags(updatedTags);

      setsearchTermTags("");
    } else {
      setsearchTermTags("");

      setSelectedTags([...selectedTags, selectedTag]);
    }
  };
  const handleTagDelete = (tagId) => {
    const updatedTags = selectedTags.filter((tag) => tag.id !== tagId);
    setSelectedTags(updatedTags);
  };

  const removeSelectedTag = (tagToRemove) => {
    const updatedTags = selectedTags.filter((tag) => tag.id !== tagToRemove.id);
    setSelectedTags(updatedTags);
  };

  const handleAddNewClickSupplier = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Manufacturer name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });

      return;
    }

    //e.preventDefault();
    setSelectedSuppiler(typedText);
    setShowListSuppiler(false);
    setsearchTermSuppiler("");
    setTypedText("");
    AddSuppier();
  };
  const handleAddNewClickTags = () => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Tag name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // Check if the typed text already exists among the selected tags
    const isExistingTag = selectedTags.some(
      (tag) => tag.name.toLowerCase() === searchTermTags.toLowerCase()
    );

    // If the typed text doesn't already exist among the selected tags, add it as a new tag
    if (!isExistingTag) {
      // Create a new tag object and add it to the selected tags array
      const newTag = { id: "3e3e3", name: searchTermTags };

      // Add a comma separator if there are existing selected tags
      const updatedTags =
        selectedTags.length > 0 ? [...selectedTags, newTag] : [newTag];

      setSelectedTags(updatedTags);
    }
    AddTags();
    // Reset the search term
    setsearchTermTags("");
  };

  const handleButtonClickSupplier = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListSuppiler(!showListSuppiler);
  };

  const handleButtonClickTags = (e) => {
    e.preventDefault(); // Prevent form submission
    setShowListTags(!showListTags);
  };

  const Validation = () => {
    let error = {};
    const numericRegex = /^[0-9]+$/;
    if (!title) {
      error.title = "Title Is Required";
    } else if (title.trim().length === 0) {
      error.title = "Title Is Required";
    }

    if (!price) {
      error.price = "Selling Price Is Required";
    }
    // else if (!numericRegex.test(price)) {
    //   error.price = "Invalid Contains non-numeric characters";
    // }
    if (!SKU) {
      error.SKU = "SKU Code Is Required";
    }
    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      AddProduct();
    }
    return error;
  };

  const ValidationDevice = () => {
    let error = {};
    const numericRegex = /^[0-9]+$/;
    if (!title) {
      error.title = "Modal Name Is Required";
    } else if (title.trim().length === 0) {
      error.title = "Modal Name Is Required";
    }

    if (!price) {
      error.price = "Selling Price Is Required";
    }
    //  else if (!numericRegex.test(price)) {
    //   error.price = "Invalid Contains non-numeric characters";
    // }
    if (!SKU) {
      error.SKU = "SKU Code Is Required";
    }
    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      AddDevice();
    }
    return error;
  };
  const ValidationDeviceDraft = () => {
    let error = {};
    const numericRegex = /^[0-9]+$/;
    if (!title) {
      error.title = "Title Is Required";
    } else if (title.trim().length === 0) {
      error.title = "Title Is Required";
    }

    if (!price) {
      error.price = "Selling Price Is Required";
    }
    // else if (!numericRegex.test(price)) {
    //   error.price = "Invalid Contains non-numeric characters";
    // }
    if (!SKU) {
      error.SKU = "SKU Code Is Required";
    }
    if (Object.keys(error).length === 0) {
      AddDeviceDraft();
    }
    return error;
  };

  const Validationd = () => {
    let error = {};
    const numericRegex = /^[0-9]+$/;
    if (!title) {
      error.title = "Title Is Required";
    } else if (title.trim().length === 0) {
      error.title = "Title Is Required";
    }

    if (!price) {
      error.price = "Selling Price Is Required";
    }
    // else if (!numericRegex.test(price)) {
    //   error.price = "Invalid Contains non-numeric characters";
    // }
    if (!SKU) {
      error.SKU = "SKU Code Is Required";
    }
    if (Object.keys(error).length === 0) {
      AddProduct1();
    }
    return error;
  };

  const navigate = useNavigate();

  const AddBrand = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 1,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddBrandApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllBrand();
          setSelectedBrandId(result?.data?.id);
          setSearchTerm("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllBrand = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllBrand?productCategoryId=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllBrand(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddSuppier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 1,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddManufactureApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllSupplier();
          setSelectedSuppierId(result?.data?.id);
          setsearchTermSuppiler("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllSupplier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetManufactureApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllSupplier(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const SubmitProduct = () => {
    setError(Validation());
  };
  const SubmitProductdraft = () => {
    setError(Validationd());
  };

  const SubmitDevice = () => {
    setError(ValidationDevice());
  };
  const SubmitDevice1 = () => {
    setError(ValidationDeviceDraft());
  };

  const AddDevice = () => {
    let local = localStorage.getItem("token");
    const tagIdsArray = selectedTags.map((tag) => tag.id.toString());

    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      //itemCategoryId: selectedCateId,
      productCategoryId: 2,
      brandId: selectedBrandId1,
      manufacturerId: selectedSupplierId,
      title: title,
      image: images,
      activeInactive: 1,
      draft: 0,
      skuCode: SKU,
      description: description,
      //tags: tagIdsArray,
      sellingPrice: price ? price : "0",
      unitCost: unitCost ? unitCost : "0",
      minimumPrice: minimumPrice ? minimumPrice : "0",
      averageCost: averageCost ? averageCost : "0",
      storageId: selectedStorageId,
      colorId: selectedColorId,
      conditionId: selectedConditionId,
      imeiNumber: imeiNumber,
      carrierId: selectedCarrierId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddProductApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          props.onpress(false);
          props.onload();
          setTitle("");
          setImages("");
          setDescription("");
          setPrice("");
          setUnitCost("");
          setAverageCost("");
          setMinimunPrice("");
          setSKUFill("");
          setSelectedBrand("");
          //setSelectedCategory("");
          //setSelectedTags([]);
          // setSelectedSuppiler("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddDeviceDraft = () => {
    let local = localStorage.getItem("token");
    const tagIdsArray = selectedTags.map((tag) => tag.id.toString());

    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      //itemCategoryId: selectedCateId,
      productCategoryId: 2,
      brandId: selectedBrandId1,
      manufacturerId: selectedSupplierId,
      title: title,
      image: images,
      activeInactive: 1,
      draft: 1,
      skuCode: SKU,
      description: description,
      //tags: tagIdsArray,
      sellingPrice: price ? price : "0",
      unitCost: unitCost ? unitCost : "0",
      minimumPrice: minimumPrice ? minimumPrice : "0",
      averageCost: averageCost ? averageCost : "0",
      storageId: selectedStorageId,
      colorId: selectedColorId,
      conditionId: selectedConditionId,
      imeiNumber: imeiNumber,
      carrierId: selectedCarrierId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddProductApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          navigate("/devicelist");
          props.onpress(false);
          props.onload();
          setTitle("");
          setImages("");
          setDescription("");
          setPrice("");
          setUnitCost("");
          setAverageCost("");
          setMinimunPrice("");
          setSKUFill("");
          setSelectedBrand("");
          //setSelectedCategory("");
          //setSelectedTags([]);
          // setSelectedSuppiler("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddBrandDevice = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 2,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddBrandApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllBrandDevice();
          setSelectedBrandId(result?.data?.id);
          setSearchTerm("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllBrandDevice = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllBrand?productCategoryId=2`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllBrand1(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const HandleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setImages1("");
        });
      }
    });
  };

  const generateSKU = () => {
    const sku = Math.floor(100000 + Math.random() * 900000)
      .toString()
      .substring(0, 6);

    setSKU(sku);
    return sku;
  };

  const handleAddNewClickDevice = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Brand name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    // e.preventDefault();
    setSelectedBrand1(typedText);
    setShowList1(false);
    setSearchTerm1("");
    setTypedText("");
    AddBrandDevice();
  };

  useEffect(() => {
    setIMEINumber(props.imei);
    GetAllBrand();
    GetAllSupplier();
    GetAllTags();
    GetAllItem();
    GetAllStorage();
    GetAllColor();
    GetAllCondition();
    GettAllCarrier();
    GetAllBrandDevice();
    generateSKU();
    //GetCustomerDevice();
  }, []);

  const AddProduct = () => {
    let local = localStorage.getItem("token");
    const tagIdsArray = selectedTags.map((tag) => tag.id.toString());

    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      itemCategoryId: selectedCateId,
      productCategoryId: 1,
      brandId: selectedBrandId,
      manufacturerId: selectedSupplierId,
      title: title,
      image: images,
      activeInactive: 1,
      draft: 0,
      skuCode: SKU,
      description: description,
      tags: tagIdsArray,
      sellingPrice: price ? price : "0",
      unitCost: unitCost ? unitCost : "0",
      minimumPrice: minimumPrice ? minimumPrice : "0",
      averageCost: averageCost ? averageCost : "0",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddProductApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          navigate("/productlist");
          setTitle("");
          setImages("");
          setDescription("");
          setPrice("");
          setUnitCost("");
          setAverageCost("");
          setMinimunPrice("");
          setSKUFill("");
          setSelectedBrand("");
          setSelectedCategory("");
          setSelectedTags([]);
          setSelectedSuppiler("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddProduct1 = () => {
    let local = localStorage.getItem("token");
    const tagIdsArray = selectedTags.map((tag) => tag.id.toString());

    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      itemCategoryId: selectedCateId,
      productCategoryId: 1,
      brandId: selectedBrandId,
      supplierId: selectedSupplierId,
      title: title,
      image: images,
      activeInactive: 1,
      draft: 1,
      skuCode: SKU,
      description: description,
      tags: tagIdsArray,
      sellingPrice: price ? price : "0",
      unitCost: unitCost ? unitCost : "0",
      minimumPrice: minimumPrice ? minimumPrice : "0",
      averageCost: averageCost ? averageCost : "0",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddProductApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          navigate("/productlist");
          setTitle("");
          setImages("");
          setDescription("");
          setPrice("");
          setUnitCost("");
          setAverageCost("");
          setMinimunPrice("");
          setSKUFill("");
          setSelectedBrand("");
          setSelectedCategory("");
          setSelectedTags([]);
          setSelectedSuppiler("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddItem = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 1,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddItemApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllItem();
          setSelectedCateId(result?.data?.id);
          setSearchTermCategory("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllItem = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllItemCategory?productCategoryId=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllItems(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddTags = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: "1",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddTagsApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setSelectedTags([...selectedTags, result.data]);
          GetAllTags();
          setsearchTermTags("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllTags = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllTag?productCategoryId=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllTags(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedBrand1, setSelectedBrand1] = useState(null);

  const handleImages = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        return;
      }

      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImage(newFile);
    }
  };

  const handleImagesDevice = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        return;
      }

      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImageDevice(newFile);
    }
  };

  const UploadImage = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "product-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setImages(result?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const UploadImageDevice = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "device-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setImages(result?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const maxDescriptionLength = 200;
  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;

    if (newDescription.length <= maxDescriptionLength) {
      setDescription(newDescription);
      setErrormsg("");
    } else if (newDescription.length > maxDescriptionLength) {
      setTimeout(() => {
        setErrormsg("Allow Maximum 200 Characters");
      }, 2000);
    }
  };

  const Validate = () => {
    let error = {};
    // if (!selectedDevice) {
    //   error.selectedDevice = "Model Name Is Required";
    // } else if (selectedDevice.trim().length === 0) {
    //   error.selectedDevice = "Model NAME Is Required";
    // }
    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      CustomerDevice();
    }
    return error;
  };

  const OnSubmit = () => {
    setError(Validate());
  };

  const CustomerDevice = () => {
    let local = localStorage.getItem("token");
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      brandId: props.brandid,
      description: description,
      imeiNumber: imeiNumber,
      customerId: props.id,
      model: selectedDevice ? selectedDevice : typedText,
      image: images1,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddCustomerDeviceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          // GetCustomerDevice();
          props.onpress(false);
          props.onload(result?.data?.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetCustomerDevice = () => {
    let local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${baseurl}getAllCustomerDevice?customerId=${props.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setAllCustomerDevice(result?.data.reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const filteredDevices = allcustomerDevice.filter((brand) => {
    const name = brand.model;
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTerm.toLowerCase());
    }

    return false;
  });

  const handleDeviceSelect = (brand) => {
    setSelectedDevice(brand.model);
    setSelectedDeviceId(brand.id);
    setShowList(false);
  };
  const handleSearchChangeDevice = (e) => {
    setSearchTerm(e.target.value);
    setTypedText(e.target.value);
    setSelectedDevice(null);
  };
  const handleButtonClickDevice = (e) => {
    e.preventDefault();
    setShowList(!showList);
  };

  const handleImages1 = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        return;
      }

      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImage1(newFile);
    }
  };

  const UploadImage1 = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "ticket-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setImages1(result?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="createproductModalLabel">
              Create New Device
            </h5>

            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => {
                props.onpress(false);
                document
                  .querySelectorAll(".modal-backdrop")
                  .forEach((el) => el.classList.remove("modal-backdrop"));
                setTitle("");
                setImages("");
                setDescription("");
                setPrice("");
                setUnitCost("");
                setAverageCost("");
                setMinimunPrice("");
                setSKUFill("");
                setSelectedBrand("");
                setSelectedCategory("");
                setSelectedTags([]);
                setSelectedSuppiler("");
              }}
            >
              <span className="close_icon"></span>
            </button>
          </div>
          <div className="modal-body">
            <div className="custom_form createproduct_form">
              <form action="" method="" className="cus_form">
                <div className="form_row product_radio_row">
                  <div className="form_column col_full">
                    <div className="form-group"></div>
                  </div>
                </div>

                <div className="product_row">
                  <div className="product_col100 cus_prod_col">
                    <h4>
                      General information<span>*</span>
                    </h4>
                    {/* <p>To start selling, all you need is a name and a price.</p> */}
                    <div className="form_row">
                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">IMEI/Serial</label>
                          <div className="formfield field_wd100">
                            <input
                              type="text"
                              className="form-control"
                              name="serialno"
                              placeholder="Enter IMEI/Serial"
                              value={imeiNumber}
                              onChange={(e) =>
                                setIMEINumber(e.target.value.trim())
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label label_red">
                            Model Name<span>*</span>
                          </label>
                          {/* <div className="formfield field_wd100">
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              placeholder="Classic Bathrobe"
                              value={title}
                              onChange={(e) => {
                                setTitle(e.target.value);
                                if (error.title) {
                                  setError({ ...error, title: "" });
                                }
                              }}
                            />{" "}


                          </div>{" "} */}

                          <div
                            className="formfield field_wd100  add_cust_field selectcustomerboxcustmlist"
                            ref={dropdownRef1}
                          >
                            <div className="tagsscustomdiv">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() => setShowList(!showList)}
                              >
                                <input
                                  type="text"
                                  className="form-control customsearchinputboxsetting  "
                                  name="firstName"
                                  placeholder="Enter Model Name"
                                  value={selectedDevice || searchTerm}
                                  onChange={handleSearchChangeDevice}
                                  onClick={() => setShowList(!showList)}
                                />
                              </div>
                              {showList && (
                                <div className="addselectbox">
                                  <ul>
                                    {filteredDevices.map((brand) => (
                                      <li
                                        key={brand.id}
                                        onClick={() =>
                                          handleDeviceSelect(brand)
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {brand.model}
                                      </li>
                                    ))}
                                  </ul>

                                  {/* <button
                                onClick={() => {
                                  handleAddNewClickDevice();
                                  if (inputRef5.current) {
                                    inputRef5.current.focus();
                                  }
                                }}
                                style={{ backgroundColor: "#9d3dd3" }}
                                type="button"
                                className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                              >
                                <span>+</span> Add New Brand
                              </button> */}
                                </div>
                              )}
                            </div>
                          </div>

                          {error.selectedDevice && (
                            <span
                              style={{
                                fontSize: "12px",
                                color: "red",
                                marginLeft: "110px",
                              }}
                            >
                              {error.selectedDevice}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form_column col_full">
                        <div className="form-group">
                          <label className="cus_label">
                            Description (optional)
                          </label>
                          <div className="formfield field_wd100">
                            <textarea
                              className="form-control"
                              name="description"
                              placeholder="Enter Description"
                              value={description}
                              onChange={handleDescriptionChange}
                            ></textarea>
                          </div>{" "}
                          {errormsg && (
                            <span
                              style={{
                                fontSize: "12px",
                                color: "red",
                                marginLeft: "110px",
                              }}
                            >
                              {errormsg}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="product_col100 cus_prod_col uploadimagedevicescustm">
                        <div className="uploadimgesrepairticketdevice">
                          <h4>Thumbnail</h4>
                          <p>Upload image of product</p>
                        </div>
                        <div className="form_row">
                          <div className="form_column col_full">
                            <div className="form-group">
                              <div className="thumbnail_img">
                                {images1 ? (
                                  <i
                                    className="fa-regular fa-circle-xmark deletimgcustm1"
                                    onClick={() => HandleDelete()}
                                  ></i>
                                ) : null}
                                {images1 ? (
                                  <>
                                    <img
                                      src={images1}
                                      className="imgescstmdisply"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="file"
                                      className="form-control"
                                      name="prod_thumnail"
                                      accept="image/jpeg, image/jpg, image/png"
                                      onChange={handleImages1}
                                    />
                                    <div className="file_upload">
                                      <img
                                        src="assets/img/thumbnail_img.webp"
                                        alt=""
                                      />
                                      <div className="file_txt">
                                        Drop your file here, or{" "}
                                        <span>click to browse</span>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix"></div>

                <div className="clearfix"></div>
              </form>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary default_btn"
              onClick={() => {
                props.onpress(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary theme_btn"
              onClick={() => OnSubmit()}
            >
              Save Device
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default TicketDeviceModal;
