import React, { useEffect, useState, useRef } from "react";
import MainSidebar from "./MainSidebar";
import SideUl from "./SideUl";
import Footer from "../Footer";
import FreeTrail from "./FreeTrail";
import { SalesReportDataApi, SalesReportdataByDateApi } from "../Utility/Api";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { usePDF } from "react-to-pdf";
import generatePDF from "react-to-pdf";

const SalesDate = () => {
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [loading, setLoading] = useState(false);
  const [salesData, setSalesData] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
  const [endDate, setEndDate] = useState(moment());
  // const [startDate, setStartDate] = useState(moment().startOf("week"));
  // const [endDate, setEndDate] = useState(moment().endOf("week"));
  // const [startDate, setStartDate] = useState(
  //   moment(new Date()).startOf("day").subtract(0, "days")
  // );
  // const [endDate, setEndDate] = useState(
  //   moment(new Date()).startOf("day").add(0, "days")
  // );
  const [CompanyName, setCompanyName] = useState("");
  const [type, setType] = useState("");
  const targetRef = useRef();

  const handleCompanyNameChange = (name) => {
    setCompanyName(name);
  };

  // const handleStartDate = (date) => {
  //   if (!date) return;

  //   setStartDate(moment(date).startOf("day"));
  //   setEndDate((prevEndDate) =>
  //     moment(date).startOf("day").isAfter(prevEndDate)
  //       ? moment(date).startOf("day").add(1, "days")
  //       : prevEndDate
  //   );
  //   console.log("startdate", moment(date).startOf("day"));
  // };

  // const handleEndDate = (date) => {
  //   if (!date) return;

  //   setEndDate(moment(date).startOf("day").add(0, "days"));
  //   console.log("enddate", moment(date).startOf("day").add(0, "days"));
  //   setTimeout(() => {
  //     SalesReportData();
  //   }, 1000);
  // };

  // const handleStartDate = (date) => {
  //   if (!date) return;

  //   const newStartDate = moment(date).startOf("day");
  //   const currentEndDate = moment(endDate).startOf("day");

  //   setStartDate(newStartDate);

  //   // Only update endDate if new startDate is after the current endDate
  //   if (newStartDate.isAfter(currentEndDate)) {
  //     setEndDate(newStartDate.clone());
  //   }
  // };

  // const handleEndDate = (date) => {
  //   if (!date) return;

  //   const newEndDate = moment(date).startOf("day");

  //   // Set endDate to the selected date
  //   setEndDate(newEndDate);
  //   // setTimeout(() => {
  //   //   SalesReportData();
  //   // }, 100);
  // };

  const handleStartDate = (date) => {
    if (!date) return;

    const newStartDate = moment(date).startOf("day");
    const currentEndDate = moment(endDate).startOf("day");

    setStartDate(newStartDate);

    // Only update endDate if new startDate is after the current endDate
    if (newStartDate.isAfter(currentEndDate)) {
      setEndDate(newStartDate.clone());
    }
  };

  const handleEndDate = (date) => {
    if (!date) return;

    const newEndDate = moment(date).startOf("day");

    // Set endDate to the selected date
    setEndDate(newEndDate);
  };

  const SalesReportData = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("dataa", raw);

    fetch(SalesReportdataByDateApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("salesdata", result);
        setSalesData(result?.data);
        setType(result.type || null);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const [totalData, setTotalData] = useState({});
  const [last, setLast] = useState(0);
  const SalesDailyReportData = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const currentDate = moment(); // Current date
    const startOfWeek = currentDate.clone().subtract(7, "days").startOf("day"); // 7 days ago from today
    const endOfWeek = currentDate.clone().endOf("day"); // End of today

    const raw = JSON.stringify({
      startDate: startOfWeek.format("YYYY-MM-DD"),
      endDate: endOfWeek.format("YYYY-MM-DD"),
      type: 1,
    });
    // const startOfWeek = moment().startOf("week").format("YYYY-MM-DD");
    // const endOfWeek = moment().endOf("week").format("YYYY-MM-DD");
    // const raw = JSON.stringify({
    //   startDate: moment(startOfWeek).format("YYYY-MM-DD"),
    //   endDate: moment(endOfWeek).format("YYYY-MM-DD"),
    //   // startDate: moment(startDate).format("YYYY-MM-DD"),
    //   // endDate: moment(endDate).format("YYYY-MM-DD"),
    //   type: 1,
    // });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("dataa", raw);

    fetch(SalesReportdataByDateApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("salesdata", result);
        setType(result.type || null);
        setSalesData(result?.data);
        const lastEntryIndex = result.data.length - 1;
        let last = result.data[lastEntryIndex];
        // console.log("resulrtlast index", lastEntryIndex);
        // console.log(" last", last);
        setLast(lastEntryIndex);
        setTotalData(last);

        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  // const SalesReportMonthlyData = () => {
  //   setLoading(true);
  //   let local = localStorage.getItem("token");
  //   const myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("Authorization", `Bearer ${local}`);
  //   const startDate = moment().startOf("month");
  //   const endDate = moment().endOf("month");

  //   const raw = JSON.stringify({
  //     startDate: startDate.format("YYYY-MM-DD"),
  //     endDate: endDate.format("YYYY-MM-DD"),
  //   });

  //   const requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };
  //   console.log("dataa", raw);

  //   fetch(SalesReportDataApi, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log("salesdata", result);
  //       setLoading(false);
  //       setSalesData(result?.data);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       console.error(error);
  //     });
  // };

  const SalesReportYearlyData = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const startDate = moment().startOf("year");
    const endDate = moment().endOf("year");

    const raw = JSON.stringify({
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("dataa", raw);

    fetch(SalesReportdataByDateApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("salesdata", result);
        setLoading(false);
        setSalesData(result?.data);
        setType(result.type || null);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const SalesRepotDataMonth = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const startDate = moment().startOf("month");
    const endDate = moment().endOf("month");

    const raw = JSON.stringify({
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(SalesReportdataByDateApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        // console.log("monthlydata", result);
        setSalesData(result?.data);
        setType(result.type || null);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
    SalesDailyReportData();
  }, []);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "-4px" : "-50px",
  };
  const mainContentStyle = {
    // marginTop: isFreeTrialVisible ? "132px" : "92px",
  };
  const mainwrapper = {
    // marginTop: isSidebarVisible ? "0" : "0",
  };
  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);

  const handleTimeRangeChange = (selectedOption) => {
    switch (selectedOption) {
      case "Daily":
        SalesDailyReportData();
        break;
      case "Monthly":
        SalesRepotDataMonth();
        // SalesReportMonthlyData();
        break;
      case "Yearly":
        SalesReportYearlyData();
        break;
      default:
        break;
    }
  };

  const printTable = () => {
    const printContents = document.querySelector(".table").innerHTML;
    const originalContents = document.body.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
    <html>
     <head>
     <style>
     @media print {
  @page {
    margin: 0; /* Remove default margins to ensure there's no space at the top and bottom */
  }
  body {
    /* Remove margins on body as well */
    padding:5px;
     height:auto
  }
  /* Hide browser print headers and footers */
  header,
  footer {
    display: none;
  }
}
     </style>

     </head>
      <body>${printContents}</body>
    </html>
  `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const formatNumber = (num) =>
    typeof num === "number" ? num.toFixed(2) : "0.00";

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}
          {isFreeTrial ? (
            <>
              <div className="app-container-trial">
                <FreeTrail />
              </div>
            </>
          ) : null}

          <div
            // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
            //   !isFreeTrialVisible ? "no-free-trial" : ""
            // }`}
            style={sidebarStyleone}
          >
            <nav
              className="navbar navbar-expand-lg main-navbar sticky"
              style={navbarStyle}
            >
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();

                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>
                  Sales by Date / <strong>Reports</strong>
                </h4>
              </div>
              <SideUl onCompanyNameChange={handleCompanyNameChange} />
            </nav>
            <MainSidebar />
            {/* Main Content */}
            <div className="main-content tableback" style={mainContentStyle}>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="common_sales_report">
                    <div className="sales_filter">
                      <div className="sales_col_left">
                        <div className="sales_type cus_sales_type">
                          <label>Type:</label>
                          <select
                            className="form-control"
                            onChange={(e) =>
                              handleTimeRangeChange(e.target.value)
                            }
                          >
                            <option value="Daily">Daily</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Yearly">Yearly</option>
                          </select>
                        </div>
                        <div className="sales_date">
                          <div className="date_icon">
                            <img src="assets/img/sales-date-icon.svg" alt="" />
                          </div>
                          <div className="date_rangepicker">
                            <DatePicker
                              id="startDate"
                              className="saledashstartdatepicker"
                              dateFormat="MM/dd/yyyy"
                              selected={startDate.toDate()}
                              onChange={handleStartDate}
                              // showMonthYearPicker
                            />
                            <span>-</span>
                            <DatePicker
                              id="endDate"
                              className="saledashenddatepicker"
                              dateFormat="MM/dd/yyyy"
                              minDate={startDate.toDate()}
                              selected={endDate.toDate()}
                              onChange={handleEndDate}
                              // showMonthYearPicker
                            />
                          </div>
                        </div>
                      </div>
                      <div className="sales_col_right">
                        {/* <div className="sales_print">
                          <a href="#" onClick={printTable}>
                            Print{" "}
                            <img src="assets/img/sales-print-icon.svg" alt="" />
                          </a>
                        </div> */}
                        <div className="sales_save_btn">
                          <button
                            className="btn orderbtn "
                            type="button"
                            onClick={SalesReportData}
                            // data-toggle="dropdown"
                            // aria-expanded="true"
                          >
                            Submit Date
                          </button>
                          <div className="dropdown">
                            <button
                              className="btn orderbtn dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-expanded="true"
                            >
                              Save
                            </button>
                            <ul
                              className="dropdown-menu"
                              x-placement="top-start"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() =>
                                    generatePDF(targetRef, {
                                      filename: "page.pdf",
                                    })
                                  }
                                >
                                  Save as a PDF
                                </a>
                              </li>
                              {/* <li>
                                <a className="dropdown-item" href="#">
                                  Save &amp; Send
                                </a>
                              </li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sales_content">
                      <div className="sales_title">
                        <h4>Sales by Date</h4>
                        <span className="comp_name">{CompanyName}</span>
                        <span className="saledate">
                          {startDate.format("MM/DD/YYYY")} -{" "}
                          {endDate.format("MM/DD/YYYY")}
                        </span>
                      </div>
                      <div className="sales_inner_content">
                        <div className="table-responsive" ref={targetRef}>
                          <table className="table">
                            <tbody>
                              <tr>
                                <td>
                                  <b>Date</b>
                                  <br />
                                  mm/dd/2024
                                </td>
                                <td>
                                  <b>Total Sales</b>
                                  <br />
                                  Total sales for date
                                </td>
                                <td>
                                  <b>Items Sold</b>
                                  <br /># of products sold
                                </td>
                                <td>
                                  <b>Refund</b>
                                  <br />
                                  Total ($)
                                </td>
                                <td>
                                  <b>COGS</b>
                                  <br />
                                  Total cost
                                </td>
                                <td>
                                  <b>Taxes</b>
                                  <br />
                                  Total Taxes
                                </td>
                                <td>
                                  <b>Margin</b>
                                  <br />
                                  Sales to Profit
                                </td>
                                <td>
                                  <b>Profit</b>
                                  <br />
                                  Net Profit
                                </td>
                              </tr>
                              {salesData?.length ? (
                                <>
                                  {salesData
                                    .slice(0, last)
                                    ?.map((item, index) => (
                                      <tr key={item?.id}>
                                        {type === 1 ? (
                                          <td>
                                            {moment(item?.startDate).format(
                                              "MM/DD/YYYY"
                                            )}{" "}
                                          </td>
                                        ) : (
                                          <td>
                                            {moment(item?.startDate).format(
                                              "MM/DD/YYYY"
                                            )}{" "}
                                            -{" "}
                                            {moment(item?.endDate).format(
                                              "MM/DD/YYYY"
                                            )}
                                          </td>
                                        )}
                                        <td>
                                          ${item?.totalSales.toFixed(2) || 0}
                                        </td>
                                        <td>{item?.totalQuantity || 0}</td>
                                        <td>
                                          ${item?.totalRefund.toFixed(2) || 0}
                                        </td>
                                        <td>
                                          ${item?.totalcost.toFixed(2) || 0}
                                        </td>
                                        <td>
                                          ${item?.totaltax.toFixed(2) || 0}
                                        </td>
                                        <td>{item?.margin.toFixed(2) || 0}%</td>
                                        <td>${item?.profit.toFixed(2) || 0}</td>
                                      </tr>
                                    ))}
                                </>
                              ) : (
                                <tr id="noDataMessage">
                                  <td colSpan="10" className="nodataav">
                                    No data available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                            {type === 1 ? (
                              <>
                                <tfoot>
                                  <tr>
                                    <td>Total</td>
                                    <td>
                                      ${formatNumber(totalData.totalSales)}
                                    </td>
                                    <td>{totalData.totalQuantity || 0}</td>
                                    <td>
                                      ${formatNumber(totalData.totalRefund)}
                                    </td>
                                    <td>
                                      ${formatNumber(totalData.totalcost)}
                                    </td>
                                    <td>${formatNumber(totalData.totaltax)}</td>
                                    <td>{formatNumber(totalData.margin)}%</td>
                                    <td>${formatNumber(totalData.profit)}</td>
                                  </tr>
                                </tfoot>
                              </>
                            ) : null}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
export default SalesDate;
